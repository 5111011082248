@font-face {
   font-family: AvenirNextBold;
   src: url(./fonts/AvenirNextLTPro-Bold.otf);
}
@font-face {
   font-family: AvenirNextRegular;
   src: url(./fonts/AvenirNextLTPro-Regular.otf);
}
@font-face {
   font-family: AvenirNextDemi;
   src: url(./fonts/AvenirNextLTPro-Demi.otf);
}

*{
  box-sizing: border-box;
  font-family: AvenirNextRegular !important;
}
html {
	-webkit-print-color-adjust: exact;
	-webkit-filter: opacity(1);
}
/* canvas  {
  font-family: AvenirNextDemi !important;
  font-weight: lighter !important;
} */
body{
  margin: 0;
}
#root > nav{
  background: #693668 !important;
}

h1, h2,.flowingTags div span {
  font-family: AvenirNextBold !important;
}

.title {
  font-size: 50px;
  line-height: 22px;
  text-align: center;
  color: #693668;
  margin: 13px 0;
  font-family: AvenirNextRegular !important;
  font-weight: lighter;
}
.titleScreen {
  margin-top: 13px;
    font-family: AvenirNextRegular !important;
    font-size: 50px;
    line-height: 22px;
    text-align: center;
    color: #693668;
    font-weight: lighter;
}
.shotTitle{
  font-family: AvenirNextRegular !important;
}
.body {
  background: #FFFFFF;
box-shadow: 0px 0px 10px rgba(105, 54, 104, 0.354048);
border-radius: 10px;
padding-top: 20px;
margin: 20px;
}

p {
font-size: 12px;
line-height: 14px;
display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;

}
.container.shot {
  padding-top: 0px;
}
.experience{
      background: #693668;
    border-radius: 4px;
    padding: 10px 30px;
    display: block;
    width: 230px;
    text-align: center;
    margin: auto;
    color: #fff;
}
.experience.shot{
  background: #693668;
  border-radius: 10px;
  padding: 0;
  display: block !important;
  width: 230px;
  text-align: center;
  margin: auto;
  color: #fff;
  font-size: 22px;
  flex: 0.4;
  transform: translateY(-5px);
  /* width: auto; */
  /* margin-top: 5px; */
}
.experience.printShow{
  flex: 0.7;
}
.experience.printShow span {
  white-space: nowrap;
}
.bioshot{
  font-family: AvenirNextRegular !important;
}
.lang {

font-size: 18px;
line-height: 21px;
text-align: right;

color: #4A4A4A;
}

.intro {
font-size: 18px;
line-height: 21px;
display: flex;
justify-content: center;
color: #693668;
}
/* .screen{
  display: none;
} */
.speak {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  align-items: center;
}

.speak > div  {
  display: flex;
  align-items: center;
}

.speak > div > span  {
  margin-left:20px;
}
/* .experience.shot{
  flex: 0.4 !important;
  width: auto;
} */
#myAudio + button {
    border: 1px solid #693668;
    padding: 5px 0;
    background: transparent;
        outline: none;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.speak > div:nth-child(1){

font-size: 18px;
line-height: 22px;
/* identical to box height, or 122% */


color: #4A4A4A;

}

.container.pdf, .container.shot{
  max-width: 1000px;
}
.container {
  margin: auto;
  position: relative;
}

.speak > div {
    display: flex;
    justify-content: center;
        flex: 1;
}

.charts {
  display: flex;
  justify-content: space-around;
}

h2 {
font-size: 25px;
line-height: 22px;
/* identical to box height, or 122% */

text-align: center;

color: #693668;
}
.mobile.show{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5px;
}
 .mobile.show > div {
   display: flex;
   align-items: center;
 }
 .mobile.show > div span {
 margin-left: 10px;
 font-size: 10px;
 }
 .carousel-control-next-icon {
   background-image: url('img/right.png') !important;
  }
  .carousel-control-prev-icon{
    background-image: url('img/left.png') !important;
 }
 .carousel-control-next-icon, .carousel-control-prev-icon{
   width: 8px !important;
    height: 14px !important;
 }
 .playBg, .playerAudioBg{
   position: absolute;
    top: 0;
    left: 0;
    background: #E1D7E1;
    bottom: 0;
 }
 .playerAudioBg{
   z-index: 999;
   background: rgb(182, 157, 182);
 }
 .printShow{
   display: none !important;
 }
 footer{
  background: radial-gradient(161.42% 155.22% at 15.48% 16.57%, #693668 0%, #F2E0BB 100%) !important;
}
.upvote > div img {
  width: 25px !important;
}
.flowingTags.web > div{
  padding: 10px !important;
}
.web h2, .interstesIn {
  font-size: 30px !important;
}

@media (min-width: 768px){
  .mobile {
    display: none !important;
  }
  .flowingTags.web > div:first-child ,.flowingTags.pdf > div:first-child  {
    margin-left: 0 !important;
  }
  .flowingTags.web > div:last-child, .flowingTags.pdf > div:last-child  {
    margin-right: 0 !important;
  }
}
@media (max-width: 768px){
  /* #root > div > div > div:nth-child(1){
    right: 10px;
  } */
  .checklistMobile{
    line-height: 18px !important;
  }
  .flowingTags.web span{
    font-size: 13px !important;
  }
  .flowingTags.web > div{
    padding: 0px 10px !important;
    height: 60px !important;
    align-self: flex-start !important;
    padding-bottom: 6px !important;
  }
   div.flowingTags.web > div > div:nth-child(1){
    height: 1px;
  }
  div.flowingTags.web > div > div:nth-child(2){
    height: 16px;
  }
  #myAudio + button{
    height: 30px !important;
  }
  #myAudio + button > svg {
    width: 23px !important;
    top: -5px !important;
  }
  .carousel-control-prev, .carousel-control-next{
    top: -270px !important;
  }
  .carousel-control-prev{
    left: 50px !important;
  }
  .carousel-control-next{
    right: 50px !important;
  }
  footer p {
    font-size: 13px !important;
  }
  .mobile.borderMobile {
    margin: 0 !important;
  }
  .openApp{
    transform: translateY(-14px);
  }
 .title.web {
  transform: translateY(-6px);
 }
  #root > nav > div{
    padding: 5px 0px !important;
  }
  .intro{
    color: #693668; 
    font-size: 13.5px;
    line-height: 18px;
    font-weight: lighter;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
  .mobile.show{
    margin-top: 5px;
  }
  .upvote{
    right: 10px !important;
    top:-5px;
    overflow: hidden;
  }
  .upvote > div {
    float: right !important;
    width: 36px !important;
    height: 31px !important;
    clear: both !important;
  }
  .upvote > div span {
    font-size: 12px;
  }
  .upvote > div span div {
      height: 7px !important;
      width: 7px !important;
      transform: translateY(4px) rotate(45deg) !important;
  }
 
  .container{
    padding: 0 10px;
  }
  .desktop {
    display: none !important;
  }
  .charts{
    flex-direction: column;
  }
  header > div {
        flex-direction: column !important;
    padding: 20px 0 !important;
    height: auto !important;
  }
  .ancrs {
    margin-top: 10px;
  }
  .mobile.show > div span {
    margin-left: 6px !important;
    font-size: 14px;
line-height: 14px;
font-weight: lighter;
font-family: 'AvenirNextRegular' !important;

color: #693668;
  }
  .body {
    margin:10px 5px;
  }
 
  .container h2,.container h1, .interstesIn{
    font-size: 22px !important;
  }
 
  #myAudio + button{
    width: 155px !important;
  }
  .experience {
    width: 155px;
    padding: 0;
  }
  .experience > span {
    font-size: 11px;
  }
  .title{
    font-family: 'AvenirNextRegular' !important;
font-size: 22px !important;
line-height: 22px;
margin-bottom: 0;
  }
  .speak{
    margin: 5px 0 15px 0;
  }
  .title.web{
    margin-top: 0;
    margin-bottom: 4px !important;
  }
  .experience.web > span{
    font-size: 14px;
  }
}
.accN{
  display: none;
}
.flowingTags.shot{
  margin-top: 5px;
  margin-bottom: 5px;
}
@media print {
  /* a::after{
    content: " (" attr(href) ") ";
  } */
  .accN{
    display: inline-block;
  }
  .interested img{
    width: 150px !important;
  }
  .interested h4 {
    font-size: 16px !important;
  }
  .notPrint{
    display: none;
  }
  #root > div > div > div.experience{
    display: none;
  }
  footer img {
    width: 110px;
  }

  #root > div > div > div:nth-child(1){
    display: none;
  }
 .audioContainer{
    display: none !important;
  }
  .printShow{
    display: inline-block !important;
  }
  .printShowFlex{
    display: flex !important;
  }
   .printText{
   font-family: 'AvenirNext';
font-size: 22px;
line-height: 21px;
text-align: center;
font-weight: bolder;
color: #693668;
transform: translateY(-28px);
 }
.charts.desktop h2{
  font-size: 24px !important;
}
.flowingTags div span{
  font-size: 24px !important;
  font-family:AvenirNextBold !important;
}
h2{
  font-size: 24px !important;
}
.body{
  margin: 0 !important;
}
.charts.desktop > div{
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer{
  border-radius: 0 !important;
}
@page { 
  margin: 0; 
  /* width:1700px; */
}
.speak {
    margin-bottom: 55px !important;
}
.charts.desktop{
  margin-bottom: 22px;
}
.flowingTags{
  margin: 2px 0px !important;
}
/* .container {
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 95% !important;
  max-width: 100% !important;
} */
}

